import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { COLOR_CONSTANTS, radius, colors, space } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { Text, H2, H1, H3 } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import usersLoveUs from 'static/images/g2_badges/users-love-us.svg'
import highPerformer from 'static/images/g2_badges/high_performer_winter_2023.svg'
import smallBiz from 'static/images/g2_badges/high_performer_small_business_winter_2023.svg'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import imageHeader from 'static/images/about/header_picture.webp'
import imageStatistics from 'static/images/about/statistics.webp'
import imageCorePrinciples from 'static/images/about/core_principles.webp'
import imageBgFeatures from 'static/images/about/bg_features.webp'

import SocialMediasSection from '../Home/components/SocialMediasSection'

const BadgeImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
  width: 110px;
`

const CORE_PRINCIPLES_START = [
  {
    title: 'Go beyond the basics',
    description:
      'Anyone can create a simple publisher or a basic chart. Our goal is to turn your posts into a force that will deliver results, employing nuanced features of each social network to ensure superior results.',
  },
  {
    title: 'Simple pricing',
    description:
      'Pricing has to be simple. Just four plans: for creators and businesses that need publishing, analytics & engagement tools, for professionals and small teams needing collaboration tools and reporting, for agencies and larger teams  looking for advanced features and power tools, and enterprise solutions.',
  },
]

const CORE_PRINCIPLES_END = [
  [
    {
      title: "Support networks others won't.",
      description:
        'Too many platforms are focused on Facebook, Twitter, LinkedIn, and Instagram. Some would add Pinterest. How about Reddit? TikTok? Our goal is to give our users the ability to have an awesome presence on any social media network.',
    },
    {
      title: 'Simplicity is a friend.',
      description:
        'Complexity is an enemy. It is easy to build features that are difficult to use. We are not looking for easy :) While we can’t make social media simpler, we can certainly make managing it easy and stress-free by finding ways to build simpler and more powerful features.',
    },
  ],
  [
    {
      title: 'Privacy and safety above all',
      description:
        'Keeping your data safe and accessible only to your team is key. There has to be no ambiguity of who has access to the data your manage. Strict adherence to the Right to be forgotten.',
    },
    {
      title: 'Truly unbeatable pricing',
      description:
        'Based on our research we will easily save our users 50% of their social media management platform cost. In some cases this number is even higher, especially if users are using more bloated, more entitled platforms.',
    },
  ],
  [
    {
      title: 'Free version',
      description:
        'We understand that not every initiative can afford to pay for an awesome social media management platform. This should not be a reason not to use the best product available.',
    },
    {
      title: 'No per user pricing for large teams.',
      description:
        'Unlimited user options for large teams. We feel that it is unfair to charge larger teams more just because they are large.',
    },
  ],
]

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledHeaderWrapper = styled(Wrapper)`
  overflow: hidden;
`

const StyledStatisticsWrapper = styled(Wrapper)`
  border-radius: 24px;
  background-color: ${colors.primary};
  max-width: 1360px;
`

const StyledStatisticsImage = styled(LocalImage)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
`

const BgImg = styled(LocalImage)`
  top: 0px;
  width: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
`

const STATISTICS = [
  {
    title: '2.92m',
    description: 'Social profiles connected',
  },
  {
    title: '4.91m',
    description: 'Monthly published posts',
  },
  {
    title: '826.1b',
    description: 'Post impressions generated',
  },
]

const About = () => {
  return (
    <App fullHeader>
      <SEO
        title="About Vista Social"
        description="We are a passionate team of marketers, engineers, and social media experts that have decided to build the last social media management platform that agencies and businesses will use. "
      />

      <StyledHeaderWrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mb="l" flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        About{' '}
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Vista Social
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Just a little bit about our team! 😊
                    </H2>
                    <H2
                      mt="l"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      We are a passionate team of marketers, engineers, and social media experts that have decided to
                      build the last social media management platform that agencies and businesses will use.
                    </H2>
                    <H2
                      mt="l"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      With so many social media solutions available, we want to deliver a modern product designed for
                      social media as it exists today, not for social media of the last decade.
                    </H2>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="100%"
                ml="auto"
                mr={{ mobile: 'auto', desktop: 0 }}
                mt={{ mobile: 'l', desktop: 0 }}
                position="relative"
              >
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="154px"
                  height="154px"
                  top="-25px"
                  left="-60px"
                  zIndex="-1"
                />
                <Box
                  position="absolute"
                  borderRadius={radius.pill}
                  bg={COLOR_CONSTANTS.SALT}
                  width="210px"
                  height="210px"
                  bottom="-90px"
                  right="-110px"
                  zIndex="-1"
                />
                <LocalImage src={imageHeader} width="100%" mx="auto" maxWidth="540px" alt="About Vista Social" />
              </Flex>
            </Grid>
            <Flex mt="l" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </StyledHeaderWrapper>

      <StyledStatisticsWrapper mx="auto">
        <Container pl="0" pr="0" position="relative" width="100%" height="100%" maxWidth="100%" zIndex="0">
          <StyledStatisticsImage src={imageStatistics} width="100%" />
          <Grid
            zIndex="1"
            my="xl"
            py="s"
            gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', mobileLarge: 'repeat(3, 1fr)' }}
            gridGap={{ mobile: 'l', tablet: 'xxl' }}
            maxWidth="730px"
            mx="auto"
          >
            {STATISTICS.map(({ title, description }, index) => (
              <Flex key={index} flexDirection="column" alignItems="center">
                <Text fontSize="48px" fontWeight="bold" color="white">
                  {title}
                </Text>
                <Text color={COLOR_CONSTANTS.SALT}>{description}</Text>
              </Flex>
            ))}
          </Grid>
        </Container>
      </StyledStatisticsWrapper>

      <Box mt="xxl" />

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          my={{ mobile: 'l', tablet: 'xxl' }}
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Core principles
          </H3>
          <Flex
            mt="xl"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
          >
            <LocalImage src={imageCorePrinciples} maxWidth="506px" mx="auto" width="100%" alt="Core principles" />
            <Flex
              flexDirection={{ mobile: 'column', tablet: 'row', desktop: 'column' }}
              justifyContent={{ mobile: 'center', tablet: 'space-between' }}
            >
              {CORE_PRINCIPLES_START.map(({ title, description }) => (
                <Flex
                  flexDirection="column"
                  key={title}
                  maxWidth={{ mobile: '100%', tablet: '445px' }}
                  width="100%"
                  mt={{ mobile: 'l', desktop: 0 }}
                >
                  <Flex alignItems="center">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            {CORE_PRINCIPLES_END.map((item, index) => (
              <Flex
                key={index}
                justifyContent={{ mobile: 'center', tablet: 'space-between' }}
                flexDirection={{ mobile: 'column', tablet: 'row' }}
              >
                {item.map(({ title, description }, secondIndex) => {
                  const isFirstColumn = secondIndex % 2 === 0
                  return (
                    <Flex
                      mt="l"
                      flexDirection="column"
                      key={title}
                      maxWidth={{ mobile: '100%', tablet: '445px', desktop: isFirstColumn ? '506px' : '445px' }}
                      width="100%"
                    >
                      <Flex alignItems="center">
                        <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                        <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                          {title}
                        </Text>
                      </Flex>
                      <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  )
                })}
              </Flex>
            ))}
          </Flex>
        </Container>
      </Wrapper>

      <SocialMediasSection />

      <Wrapper height="580px">
        <BgImg src={imageBgFeatures} />
        <Flex
          px="m"
          mx="auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100%"
          width="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color="white">
            Modern SMM platform for brands and agencies ✨
          </H3>
          <Flex mt="m" pt="s" maxWidth="508px" width="100%" justifyContent="center">
            <H3 fontSize="3xl" fontWeight="bold" color="white">
              Explore our features, and beat the algorithm! 🚀
            </H3>
          </Flex>
        </Flex>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default About
